import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Image from 'next/image';

const Logo = styled((props) => {
  const { variant, width, ...other } = props;

  return (
    <Image
      {...other}
      alt=""
      height={70}
      src="/images/logo.svg"
      width={300}
    />
  );
})``;

Logo.defaultProps = {
  variant: 'primary',
};

Logo.propTypes = {
  variant: PropTypes.oneOf(['light', 'primary']),
};

export default Logo;
