/* eslint-disable camelcase */
import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from '@mui/material/styles';
import { Open_Sans } from 'next/font/google';
import merge from 'lodash/merge';
import { baseThemeOptions } from './base-theme-options';
import { darkThemeOptions } from './dark-theme-options';
import { lightThemeOptions } from './light-theme-options';

export const createTheme = (config, overrides = null) => {
  if (config.fontFamily) {
    baseThemeOptions.typography.fontFamily = config.fontFamily;
  }

  let theme = createMuiTheme(
    baseThemeOptions,
    merge(config.mode === 'dark' ? darkThemeOptions : lightThemeOptions, overrides),
    {
      direction: config.direction,
    },
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

export const DEFAULT_FONT = Open_Sans({
  subsets: ['latin'],
  display: 'swap',
});

export const ORGANIZATION_TYPE_COLORS = {
  researchInstitution: 'primary.contrastText',
  instituteCentralFacilityChair: 'primary.contrastText',
  subgroup: 'text.primary',
  company: 'secondary.contrastText',
  location: 'secondary.contrastText',
  department: 'secondary.contrastText',
  federalMinistry: 'primary.contrastText',
  referat: 'text.primary',
  projectSponsor: 'primary.contrastText',
  workUnit: 'text.primary',
  press: 'primary.contrastText',
};

export const ORGANIZATION_TYPE_BGCOLORS = {
  companyInstitutionUniversity: 'transparent',
  departmentInstitute: 'transparent',
  researchInstitution: '#000',
  instituteCentralFacilityChair: '#757575',
  subgroup: '#E0E0E0',
  company: 'secondary.main',
  location: 'secondary.light',
  department: '#fefadf',
  federalMinistry: 'primary.main',
  referat: 'primary.light',
  projectSponsor: 'primary.main',
  workUnit: 'primary.light',
  press: '#388e3c',
};

export const TABLE_MIN_WIDTH_COLUMN = {
  width: '1px',
  whiteSpace: 'nowrap',
};
