import '../wdyr';
import '../common/i18n';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'leaflet/dist/leaflet.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'leaflet.markercluster/dist/MarkerCluster.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import '../common/theme/styles.css';
import '@components/map/MarkerCluster.css';
import '@components/map/MarkerCluster.Default.css';
import { useTranslation } from 'react-i18next';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { ApolloProvider } from '@apollo/client';
import AppBaseProvider from '@components/AppBaseProvider';
import AuthSuspense from '@components/AuthSuspense';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import Head from 'next/head';
import { LocalizationProvider } from '@mui/x-date-pickers';
import NextApp from 'next/app';
import { Provider as ReduxProvider } from 'react-redux';
import { init } from '@socialgouv/matomo-next';
import Router, { useRouter } from 'next/router';
import * as Sentry from '@sentry/browser';
import {
  SessionProvider,
  useSession,
} from 'next-auth/react';
import { SnackbarProvider } from 'notistack';
import SplashScreen from '@components/SplashScreen';
import { ThemeProvider } from '@mui/material/styles';
import { createEmotionCache } from '@utils/create-emotion-cache';
import nProgress from 'nprogress';
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import IFrameViewer from '@components/IFrameViewer';
import getConfig from 'next/config';
import {
  createTheme,
  DEFAULT_FONT,
} from '../common/theme';
import { store } from '../common/store';
import { useApollo } from '../common/store/graphql';

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

const clientSideEmotionCache = createEmotionCache();
const { publicRuntimeConfig } = getConfig();

function App(props) {
  const {
    emotionCache = clientSideEmotionCache,
    pageProps,
  } = props;
  const apolloClient = useApollo(pageProps);
  const router = useRouter();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // gtm.initialize(gtmConfig);
      // hotjar.initialize(3744991, 6);

      // Redirect old domain
      if (window.location.hostname === 'projekte.batterieforum-deutschland.de') {
        window.location.href = window.location.href.replace('projekte.batterieforum-deutschland.de', 'becosearch.com');
      }
    }
  }, []);
  useEffect(() => {
    if (publicRuntimeConfig.MATOMO_URL && publicRuntimeConfig.MATOMO_SITE_ID) {
      init({
        url: publicRuntimeConfig.MATOMO_URL,
        siteId: publicRuntimeConfig.MATOMO_SITE_ID,
        disableCookies: true,
      });
    }
  }, []);

  let canonicalUrl = router.asPath.split('#')[0].split('?')[0];
  canonicalUrl = `https://becosearch.com${canonicalUrl}`;

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>
          BecoSearch
        </title>
        <meta
          content="initial-scale=1, width=device-width"
          name="viewport"
        />
        <link href={canonicalUrl} rel="canonical" />
        <meta content={canonicalUrl} property="og:url" />
        {/* eslint-disable-next-line react/no-invalid-html-attribute */}
        <link href="/cms/api/dk-sitemap/sitemap_index.xml" rel="sitemap" title="Sitemap" type="application/xml" />
      </Head>
      <main className={DEFAULT_FONT.className}>
        <ReduxProvider store={store}>
          <SessionProvider session={pageProps.session}>
            <ApolloProvider client={apolloClient}>
              <ThemeProvider
                theme={createTheme({
                  direction: 'ltr',
                  responsiveFontSizes: true,
                  mode: 'light',
                  fontFamily: DEFAULT_FONT.style.fontFamily,
                })}
              >
                <SnackbarProvider
                  anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'top',
                  }}
                  maxSnack={3}
                >
                  <CssBaseline />
                  <AppContent {...props} />
                </SnackbarProvider>
              </ThemeProvider>
            </ApolloProvider>
          </SessionProvider>
        </ReduxProvider>
      </main>
    </CacheProvider>
  );
}

function AppContent(props) {
  const {
    Component,
    pageProps,
    baseProps,
  } = props;
  const { i18n } = useTranslation();
  const { data: session } = useSession();

  const getLayout = Component.getLayout ?? ((page) => page);
  const getHeader = Component.getHeader ?? (() => null);

  useEffect(() => {
    Sentry.setUser(session?.user ? {
      id: session.user.id,
      username: session.user.username,
    } : null);

    if (hotjar.initialized() && session?.user) {
      hotjar.identify(session.user.id, { username: session.user.username });
    }
  }, [session?.user]);

  return (
    <LocalizationProvider
      adapterLocale={i18n.language}
      dateAdapter={AdapterLuxon}
    >
      {getHeader(pageProps)}
      <AuthSuspense fallback={<SplashScreen />}>
        <AppBaseProvider
          {...baseProps}
          fallback={<SplashScreen />}
        >
          {getLayout(
            <Component {...pageProps} />,
          )}
          <IFrameViewer />
        </AppBaseProvider>
      </AuthSuspense>
    </LocalizationProvider>
  );
}

App.getInitialProps = async (appContext) => {
  const appProps = await NextApp.getInitialProps(appContext);

  return {
    ...appProps,
    baseProps: {
      host: typeof window === 'undefined'
        ? getHostname(appContext.ctx.req.headers.host)
        : window.location.hostname,
    },
  };
};

function getHostname(host) {
  if (host.includes(':')) {
    return host.substring(0, host.indexOf(':'));
  }

  return host;
}

export default App;
