import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  showChangeRequests: false,
};

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganization(state, action) {
      const { id, showChangeRequests } = action.payload ?? {};
      // eslint-disable-next-line no-param-reassign
      state.id = id;
      // eslint-disable-next-line no-param-reassign
      state.showChangeRequests = showChangeRequests ?? false;
    },
  },
});

export const { setOrganization } = slice.actions;

export const selectCurrentOrganization = (state) => state.organization;

export default slice.reducer;
