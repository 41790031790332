import PropTypes from 'prop-types';

export default function AppBaseProvider({ host, children, fallback }) {
  return children;
}

AppBaseProvider.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node.isRequired,
};
