import {
  FiDatabase,
  FiGrid,
  FiTag,
  FiUsers,
} from 'react-icons/fi';
import { LiaIndustrySolid } from 'react-icons/lia';
import { RiOrganizationChart } from 'react-icons/ri';

import resources from './i18n';

export const rootPath = '/member';

const config = {
  name: 'member',
  rootPath,
  i18n: {
    resources,
  },
  menu: {
    getSections: (t) => [
      {
        title: t('modules.member.title'),
        acl: ['plugin::users-permissions.user.me'],
        membersortindex: 0,
        items: [
          {
            title: t('modules.member.menu.dashboard'),
            path: `${rootPath}/dashboard`,
            icon: <FiGrid />,
            acl: ['plugin::users-permissions.user.me'],
          },
          {
            title: t('modules.member.menu.projects'),
            path: `${rootPath}/projects`,
            icon: <FiDatabase />,
            acl: [
              'plugin::users-permissions.user.me',
              'api::project.project.find',
            ],
          },
          {
            title: t('modules.member.menu.organizations'),
            path: `${rootPath}/organizations`,
            icon: <RiOrganizationChart />,
            acl: [
              'plugin::users-permissions.user.me',
              'api::organization.organization.find',
            ],
          },
          {
            title: t('modules.member.menu.infrastructure'),
            path: `${rootPath}/infrastructure/infrastructures`,
            icon: <LiaIndustrySolid />,
            acl: [
              'plugin::strapi-plugin-users-extended.meController.isExternalUser',
              'plugin::users-permissions.user.me',
              'api::infrastructure.infrastructure.find',
            ],
          },
          {
            title: t('modules.member.menu.infrastructure'),
            path: `${rootPath}/infrastructure`,
            icon: <LiaIndustrySolid />,
            acl: [
              'plugin::users-permissions.user.me',
              'api::infrastructure.infrastructure.find',
              'api::infrastructure.infrastructure.create',
              'api::infrastructure.infrastructure.update',
              'api::infrastructure.infrastructure-template.find',
            ],
            children: [
              {
                title: t('modules.member.menu.infrastructures'),
                path: `${rootPath}/infrastructure/infrastructures`,
                acl: [
                  'plugin::users-permissions.user.me',
                  'api::infrastructure.infrastructure.find',
                  'api::infrastructure.infrastructure.update',
                ],
              },
              {
                title: t('modules.member.menu.infrastructureTemplates'),
                path: `${rootPath}/infrastructure/templates`,
                acl: [
                  'plugin::users-permissions.user.me',
                  'api::infrastructure.infrastructure-template.find',
                  'api::infrastructure.infrastructure-template.update',
                ],
              },
            ],
          },
          {
            title: t('modules.member.menu.contacts'),
            path: `${rootPath}/contacts`,
            icon: <FiUsers />,
            acl: [
              'plugin::users-permissions.user.me',
              'plugin::users-permissions.user.find',
            ],
          },
          {
            title: t('modules.member.menu.tags.title'),
            path: `${rootPath}/tags`,
            icon: <FiTag />,
            acl: [
              'plugin::users-permissions.user.me',
              'api::tag-item.tag-item.find',
              'api::tag-item.tag-item.create',
            ],
          },
        ],
      },
    ],
  },
};

export default config;
