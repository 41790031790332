import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_ORGANIZATION_TAG_ROOT } from '@config';

const initialState = {
  tag: DEFAULT_ORGANIZATION_TAG_ROOT,
};

const slice = createSlice({
  name: 'organization-tag-root',
  initialState,
  reducers: {
    setTagRoot(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.tag = action.payload;
    },
  },
});

export const { setTagRoot } = slice.actions;

export const selectCurrentTagRoot = (state) => state['organization-tag-root'];

export default slice.reducer;
