import { FiMapPin } from 'react-icons/fi';
import { MAIN_MENU_CONTENT_ITEMS } from '@config';
import resources from './i18n';

export const rootPath = '/projects';

const config = {
  name: 'projects',
  rootPath,
  i18n: {
    resources,
  },
  menu: {
    getSections: (t) => [
      {
        title: t('modules.projects.menu.mapView'),
        acl: [],
        membersortindex: 1,
        items: [
          {
            title: t('modules.projects.menu.organizations'),
            path: '/organizations',
            icon: <FiMapPin />,
            acl: [],
          },
          {
            title: t('modules.projects.menu.projects'),
            path: rootPath,
            icon: <FiMapPin />,
            acl: [],
          },
        ],
      },
      {
        title: 'Links',
        acl: [],
        membersortindex: 2,
        hiddenDesktop: true,
        items: [
          ...MAIN_MENU_CONTENT_ITEMS.primary,
          ...MAIN_MENU_CONTENT_ITEMS.secondary,
        ]
          .filter((item) => item.icon == null)
          .map((item) => ({
            title: t(item.label),
            path: t(item.path),
            acl: item.acl,
          })),
      },
    ],
  },
};

export default config;
