import { createSlice } from '@reduxjs/toolkit';
import { FILTER_ORGANIZATION_ROLE } from '@modules/projects/config';

const initialState = {
  role: FILTER_ORGANIZATION_ROLE[0].value,
};

const slice = createSlice({
  name: 'project-organization-role',
  initialState,
  reducers: {
    setOrganizationRole(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.role = action.payload;
    },
  },
});

export const { setOrganizationRole } = slice.actions;

export const selectCurrentOrganizationRole = (state) => state['project-organization-role'];

export default slice.reducer;
