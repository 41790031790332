import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  url: null,
};

const slice = createSlice({
  name: 'iframe',
  initialState,
  reducers: {
    setUrl(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.url = action.payload.url;
    },
  },
});

export const { setUrl } = slice.actions;

export const selectCurrentUrl = (state) => state.iframe.url;

export default slice.reducer;
