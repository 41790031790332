import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '@store';
import { selectCurrentUrl, setUrl } from '@slices/iframe';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { FiX } from 'react-icons/fi';
import { styled } from '@mui/material/styles';

const IFrame = styled('iframe')(({ theme }) => ({
  border: 'none',
  width: '100%',
  height: '100%',
}));

export default function IFrameViewer() {
  const url = useSelector(selectCurrentUrl);
  const { closeIFrameViewer } = useIFrameViewer();

  return (
    <Viewer
      isOpen={Boolean(url)}
      onClose={closeIFrameViewer}
      url={url}
    />
  );
}

function Viewer(props) {
  const {
    url,
    isOpen,
    onClose,
  } = props;
  const frame = useRef();
  // eslint-disable-next-line no-unused-vars
  const [title, setTitle] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setTitle('Wird geladen...');
  }, [setTitle, url]);

  const handleLoadIFrame = useCallback(() => {
    try {
      setTitle(frame.current.contentDocument.title || url);
    } catch (e) {
      console.error(e);
      setTitle(url);
    }
  }, [setTitle, url]);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={onClose}
      open={isOpen}
      PaperProps={{
        sx: { height: '100%' },
      }}
      scroll="paper"
    >
      <Paper
        sx={{
          position: 'absolute',
          right: 10,
          top: 10,
        }}
        variant="outlined"
      >
        <Tooltip
          placement="left"
          title={t('components.IFrameViewer.tooltips.close')}
        >
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <FiX />
          </IconButton>
        </Tooltip>
      </Paper>
      <DialogContent dividers sx={{ p: 0, height: '100%', overflow: 'hidden' }}>
        <IFrame
          ref={frame}
          onLoad={handleLoadIFrame}
          src={url ? `/api/proxy?url=${encodeURIComponent(url)}` : null}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          {t('shared.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function useIFrameViewer() {
  const dispatch = useDispatch();
  const actions = useMemo(() => ({
    openIFrameViewer: (url) => dispatch(setUrl({ url })),
    closeIFrameViewer: () => dispatch(setUrl({ url: null })),
  }), [dispatch]);

  return actions;
}
