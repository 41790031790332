import { Box } from '@mui/material';
import { keyframes } from '@emotion/react';
import Logo from './Logo';

export default function SplashScreen() {
  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center',
        left: 0,
        p: 3,
        position: 'fixed',
        top: 0,
        width: '100vw',
        zIndex: 2000,
      }}
    >
      <Logo
        sx={{
          width: 246,
          height: 50,
        }}
        variant="light"
      />
    </Box>
  );
}
