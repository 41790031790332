export const DEFAULT_VIEW = 'maplist';
export const DEFAULT_VIEW_MOBILE = 'map';

export const FILTER_PROJECT_TYPES = [
  {
    id: 'ij',
    value: ['individualProject', 'jointProject'],
  },
  {
    id: 'is',
    value: ['individualProject', 'subProject'],
  },
  {
    id: 'ijs',
    isListOnly: true,
    value: ['individualProject', 'jointProject', 'subProject'],
  },
];

export const FILTER_ORGANIZATION_ROLE = [
  {
    id: 'executing',
    value: 'primary_executing_org',
  },
  {
    id: 'beneficiary',
    value: 'final_beneficiary_org',
  },
];

export const PROJECT_TYPES_ABBREVIATIONS = {
  jointProject: 'VP',
  individualProject: 'EP',
  subProject: 'TP',
};

export const PROJECT_TAG_REFERENCES = [
  {
    id: 'energy-storage-type',
    label: 'Energiespeichertyp',
    group: 1,
    showInLegend: true,
  },
  {
    id: 'anode',
    label: 'Anode',
    group: 1,
  },
  {
    id: 'cathode',
    label: 'Kathode',
    group: 1,
  },
  {
    id: 'special-properties',
    label: 'besondere Eigenschaften',
    group: 1,
  },
  {
    id: 'electrolyte',
    label: 'Elektrolyt',
    group: 1,
  },
  {
    id: 'separator',
    label: 'Separator',
    group: 1,
  },
  {
    id: 'cell-format',
    label: 'Zellformat',
    group: 1,
  },
  {
    id: 'field-of-application',
    label: 'Anwendungsgebiet',
    group: 2,
    showInLegend: true,
  },
  {
    id: 'research-subject',
    label: 'Forschungsgegenstand',
    group: 2,
    showInLegend: true,
  },
  {
    id: 'research-area',
    label: 'Forschungsgebiet',
    group: 2,
    showInLegend: true,
  },
  {
    id: 'method',
    label: 'Methode',
    group: 2,
  },
  {
    id: 'investigated-property',
    label: 'Eigenschaft',
    group: 2,
  },
];

export const PROJECT_TAG_REFERENCES_FILTER = [
  {
    id: 'battery',
    label: 'Batterie',
  },
  {
    id: 'research',
    label: 'Forschung',
  },
];
